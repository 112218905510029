import React, { useEffect, useState } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import isLogin from '../../utils/authority'
import { siginOut } from '../../api/auth/auth'

const Header = () => {
  const navigate = useNavigate()

  const isAdmin = isLogin()

  const [scrolling, setScrolling] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const signOut = () => {
    siginOut().then(res => {
      window.location.href = '/'
    })
  }

  return (
    <HeaderWrap $scrolling={scrolling}>
      <HeaderTopWrap>
        <HeaderTopInnerWrap>
          <HeaderTopLogoImgWrap>
            <HeaderTopLogoImg
              src={`${process.env.PUBLIC_URL}/img/main/logo.png`}
              alt=""
              onClick={e => {
                navigate('/')
              }}
            />
          </HeaderTopLogoImgWrap>
          <HeaderMidInnerWrap>
            <HeaderMidNavbar>
              <Navbar.Collapse>
                <Nav>
                  <HeaderMidNavDropdown title="회사소개" $scrolling={scrolling}>
                    <HeaderMidNavDropdown.Item href="/company/intro">소개</HeaderMidNavDropdown.Item>
                  </HeaderMidNavDropdown>
                  <HeaderMidNavDropdown title="거래안전보장제도" $scrolling={scrolling}>
                    <HeaderMidNavDropdown.Item href="/security/guarantee">거래안전보장</HeaderMidNavDropdown.Item>
                    <HeaderMidNavDropdown.Item href="/security/policy">보안정책</HeaderMidNavDropdown.Item>
                  </HeaderMidNavDropdown>
                  <HeaderMidNavDropdown title="프로그램 문의" $scrolling={scrolling}>
                    <HeaderMidNavDropdown.Item href="/customer/hts">HTS 문의</HeaderMidNavDropdown.Item>
                    <HeaderMidNavDropdown.Item href="/customer/mts">MTS 문의</HeaderMidNavDropdown.Item>
                    <HeaderMidNavDropdown.Item href="https://open.kakao.com/o/scqpSfJg">
                      1:1 카톡상담
                    </HeaderMidNavDropdown.Item>
                  </HeaderMidNavDropdown>
                  {isAdmin && (
                    <HeaderMidNavDropdown title="관리" $scrolling={scrolling}>
                      <HeaderMidNavDropdown.Item href="/management/customer">상담 관리</HeaderMidNavDropdown.Item>
                      <HeaderMidNavDropdown.Item onClick={signOut}>로그아웃</HeaderMidNavDropdown.Item>
                    </HeaderMidNavDropdown>
                  )}
                </Nav>
              </Navbar.Collapse>
            </HeaderMidNavbar>
          </HeaderMidInnerWrap>
        </HeaderTopInnerWrap>
      </HeaderTopWrap>
    </HeaderWrap>
  )
}

export default Header

const HeaderWrap = styled.div`
  width: 100%;
  position: fixed;
  top: 44px;
  left: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 60px;
  z-index: 1000;

  ${props =>
    props.$scrolling &&
    `
    background: white;
`}
`
const HeaderTopWrap = styled.div`
  width: 100%;
  height: 100%;
`

const HeaderTopInnerWrap = styled.div`
  width: 1260px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  gap: 10px;
`

const HeaderMidInnerWrap = styled.div`
  width: 1260px;
  height: 100%;
  display: flex;
  align-items: center;
`

const HeaderMidNavbar = styled(Navbar)`
  margin-left: auto;
`

const HeaderMidNavDropdown = styled(NavDropdown)`
  // width: 160px;
  min-width: max-content;
  padding: 0 10px;
  text-align: center;

  .nav-item {
    // color: black !important;
    color: ${props => (props.$scrolling ? 'black' : 'white')};
    font-size: 19px;
  }

  .nav-link {
    // color: black !important;
    color: ${props => (props.$scrolling ? 'black' : 'white')};
    font-size: 19px;
  }

  .nav-link:focus,
  .nav-link:hover {
    color: lightgrey;
  }
`

const HeaderMidNavLink = styled(Nav.Link)`
  // height: 20px;
  color: black !important;
  font-size: 17px;
  text-align: center;
  width: 150px;
  border-right: solid 1px #ccc;
  &:hover,
  &:focus,
  &:active {
    color: red !important;
  }

  &.active,
  &.active:focus,
  &.active:hover {
    color: white !important;
  }
`

const HeaderTopLogoImgWrap = styled.div`
  width: 240px;
  height: 100%;
  position: relative;
  // border: 1px solid red;
  display: flex;
  align-items: center;
`

const HeaderTopLogoImg = styled.img`
  position: relative;
  width: 100%;
  // height: 37px;
  height: auto;

  &:hover {
    cursor: pointer;
  }

  z-index: 500;
`
