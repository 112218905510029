import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Footer = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // true 보이기
  const [isCustomer, setIsCustomer] = useState(false)

  useEffect(() => {
    const path = location?.pathname
    if (path === '/customer/hts' || path === '/customer/mts' || path === '/management/customer') {
      setIsCustomer(true)
    } else {
      setIsCustomer(false)
    }
  }, [location])

  return (
    <FooterWrap isCustomer={isCustomer}>
      <FooterInnerWrap>
        <FooterMenu>
          <FooterMenuTitle>회사 소개</FooterMenuTitle>
          <div>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/company/intro'
              }}
            >
              소개
            </FooterMenuContent>
          </div>
        </FooterMenu>
        <FooterMenu>
          <FooterMenuTitle>거래안전보장제도</FooterMenuTitle>
          <div>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/security/guarantee'
              }}
            >
              거래안전보장
            </FooterMenuContent>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/security/policy'
              }}
            >
              보안정책
            </FooterMenuContent>
          </div>
        </FooterMenu>
        <FooterMenu>
          <FooterMenuTitle>프로그램 문의</FooterMenuTitle>
          <div>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/customer/mts'
              }}
            >
              HTS 문의
            </FooterMenuContent>
            <FooterMenuContent
              onClick={e => {
                window.location.href = '/customer/mts'
              }}
            >
              MTS 문의
            </FooterMenuContent>
          </div>
        </FooterMenu>
      </FooterInnerWrap>
      <FooterLogoBox>
        <HeaderTopLogoImg
          src={`${process.env.PUBLIC_URL}/img/main/logo.png`}
          alt=""
          onClick={e => {
            navigate('/')
          }}
        />
        <p>회사명 : 히트스탁</p>
        <p>주소 : 서울특별시 강남구 역삼동 708-4</p>
        <p>TEL : 010-8097-3847</p>
        <p>Copyright © HIT STOCK 2018. All rights reserved.</p>
      </FooterLogoBox>
    </FooterWrap>
  )
}

export default Footer

const HeaderTopLogoImg = styled.img`
  position: relative;
  width: 200px;
  // height: 37px;
  height: auto;
  margin-top: -20px;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }

  z-index: 500;
`

const FooterWrap = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 520px;
  clear: both;
  background: white;
  padding: 60px 0 40px 0;
  border-top: 1px solid #626467;

  ${props =>
    props.isCustomer &&
    `
    height: 350px;
    padding: 60px 0 20px 0;
  `}
`

const FooterInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;

  padding: 0 150px;
`

const FooterMenuTitle = styled.p`
  font-size: 14px;
  line-height: 17.53px;
  color: #000;
  margin-bottom: 8px;
  font-weight: 700;
`

const FooterMenuContent = styled.p`
  font-size: 14px;
  line-height: 17.53px;
  font-weight: 500;
  color: #000;
  margin-bottom: 8px;
  cursor: pointer;
  margin-bottom: 8px;

  &:first-child {
    margin-top: 20px;
  }

  &:hover {
    color: #fdaa12;
  }
`

const FooterMenu = styled.div`
  width: 160px;
`

const FootterIconBox = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;

  div {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
  }
`

const FooterLogoBox = styled.div`
  width: 100%;
  margin-top: 80px;
  text-align: center;

  p {
    word-break: break-all;
    font-size: 12px;
    line-height: 24px;
    color: #000;
    font-weight: 400;
  }
`

const FooterFirstWrap = styled.div`
  width: 100%;
  min-height: 243px;

  // background-image: url(${process.env.PUBLIC_URL}/img/main/cop.jpg);
  background: url(${process.env.PUBLIC_URL}/img/main/cop1.png) no-repeat center;
  // background-position: center;

  display: flex;
  justify-content: end;
  align-items: center;

  padding-left: 50px;
`

const FooterFirstInfoWrap = styled.div`
  width: calc(100% / 2);

  display: flex;
  flex-direction: column;

  gap: 15px;

  float: right;
`

const FooterFirstInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: left;

  color: #000;
`

const FooterSecondWrap = styled.div`
  background: #262626;
  min-height: 50px;
`

const FooterSecondInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  display: flex;

  padding: 20px 0;
`

const FooterSecondInnerIcon = styled.img`
  width: 24px;
  height: 20px;
`

const FooterSecondInnerInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;

  color: rgb(119, 119, 119);
`

const FooterThirdWrap = styled.div`
  background: #000;
  padding: 24px 0;
`

const FooterThirdInnerWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FooterThirdInnerInfo = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: white;
`
