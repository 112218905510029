import React, { useEffect, useState } from 'react'
import { Button, CloseButton, Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import isLogin from '../../utils/authority'
import { siginOut } from '../../api/auth/auth'
// import { clearLocalStorage, getUserId, isLogin } from '../../utils/authority'

const MobileHeader = () => {
  const navigate = useNavigate()

  const isAdmin = isLogin()

  const [scrolling, setScrolling] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const signOut = () => {
    siginOut().then(res => {
      window.location.href = '/'
    })
  }

  return (
    <HeaderWrap $scrolling={scrolling}>
      <HeaderTopWrap>
        <HeaderTopInnerWrap>
          <HeaderTopLogoImgWrap>
            <HeaderTopLogoImg
              src={`${process.env.PUBLIC_URL}/img/main/logo.png`}
              alt=""
              onClick={e => {
                navigate('/')
              }}
            />
          </HeaderTopLogoImgWrap>

          <CustomNavbar expand={false}>
            <Navbar.Toggle />
            <CustomNavbarOffcanvas placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title />
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <NavDropdown title="회사소개">
                    <NavDropdown.Item href="/mobile/company/intro">소개</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="거래안전보장제도">
                    <NavDropdown.Item href="/mobile/security/guarantee">거래안전보장</NavDropdown.Item>
                    <NavDropdown.Item href="/mobile/security/policy">보안정책</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="프로그램 문의">
                    <NavDropdown.Item href="/mobile/customer/hts">HTS 문의</NavDropdown.Item>
                    <NavDropdown.Item href="/mobile/customer/mts">MTS 문의</NavDropdown.Item>
                    <NavDropdown.Item href="https://open.kakao.com/o/scqpSfJg">1:1 카톡상담</NavDropdown.Item>
                  </NavDropdown>
                  {isAdmin && (
                    <NavDropdown title="관리">
                      <NavDropdown.Item href="/mobile/management/customer">상담 관리</NavDropdown.Item>
                      <NavDropdown.Item onClick={signOut}>로그아웃</NavDropdown.Item>
                    </NavDropdown>
                  )}
                </Nav>
              </Offcanvas.Body>
            </CustomNavbarOffcanvas>
          </CustomNavbar>
        </HeaderTopInnerWrap>
      </HeaderTopWrap>
    </HeaderWrap>
  )
}

export default MobileHeader

const CustomNavbar = styled(Navbar)`
  width: 100%;

  button {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
    margin-left: auto;
  }

  .navbar-toggler {
    padding: 0 !important;
  }
`

const CustomNavbarOffcanvas = styled(Navbar.Offcanvas)`
  background: #fff;
  color: black;

  &.offcanvas {
    max-width: 240px !important;
  }
`

const HeaderWrap = styled.div`
  width: 100%;
  position: fixed;
  top: 44px;
  left: 0;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #ccc;
  background: white;
  height: 60px;
  z-index: 1000;
`
const HeaderTopWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 16px;
`

const HeaderTopInnerWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
`

const HeaderTopLogoImgWrap = styled.div`
  width: 250px;
  position: relative;
  margin: auto 0;
`

const HeaderTopLogoImg = styled.img`
  position: relative;
  width: 100%;

  height: auto;
  &:hover {
    cursor: pointer;
  }

  z-index: 200;
`
